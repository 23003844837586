import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import CenterTextSection from '../components/center-text-section'
import Image from '../components/image'
import Link from '../components/link'
import Sponsors from '../components/sponsors'
import VenueHireRequest from '../components/venue-hire-request'
import { classes } from '../util/components'
import styles from './venue-hire.module.css'
import SEO from '../components/seo'

export const VenueHireTemplate = ({ page, venueNodes, location }) => {
  const {
    frontmatter: { header, bodyHtml, bottomSection, clients },
  } = page

  const venues = venueNodes
    ? venueNodes.nodes.map(n => ({
        ...n.frontmatter,
        ...n.fields,
        body: n.html,
      }))
    : []
  const headerImage = header.image.childImageSharp ? (
    <Image
      className={styles.headerImage}
      fluid={header.image.childImageSharp.fluid}
    />
  ) : null

  return (
    <>
    <SEO 
      title={"Venue rental | Space rental | Event space rental"}
      description={"The Projector is available for private hire for personal and corporate events. For a unique and creative space to host your event, get in touch now with us now!"}
    />
    <div className="container flex flex-col justify-between py-5 lg:py-20">
      <div className={styles.header}>
        <div className={styles.leftColumn}>
          <div className={styles.innerWrapper}>
            <h1>{header.title}</h1>
            <div className={styles.mobileHeaderImage}>{headerImage}</div>
            <div dangerouslySetInnerHTML={{ __html: header.bodyHtml }}></div>
            {header.callToAction && header.callToAction.linkURL ? (
              <a
                className={classes(styles.callToAction, 'btn-primary')}
                href={header.callToAction.linkURL}
              >
                {header.callToAction.label}
              </a>
            ) : null}
          </div>
        </div>
        <div className={styles.rightColumn}>{headerImage}</div>
      </div>
      {bodyHtml ? (
        <div
          className={styles.passage}
          dangerouslySetInnerHTML={{ __html: bodyHtml }}
        ></div>
      ) : null}

      <div className="mt-10 lg:mt-32">
        <h1 className={styles.venuesHeader}>Choose a space that's for you</h1>
        <div className={styles.venues}>
          {venues.map((v, i) => (
            <div className={styles.venue} key={i}>
              <Link className="block w-full overflow-hidden mb-8" to={v.slug}>
                {header.image.childImageSharp ? (
                  <Image
                    className={`${styles.venueImage} ${styles.imageCard}`}
                    fluid={v.coverImage.childImageSharp.fluid}
                  />
                ) : null}
              </Link>
              <h2 className={styles.venueTitle}>{v.title}</h2>
              <div className={styles.venueSubtitle}>
                {(v.descriptors || []).length
                  ? v.descriptors.map((d, i) => (
                      <span key={i} className={styles.descriptor}>
                        {d}
                      </span>
                    ))
                  : null}
              </div>
              <div className={styles.venueBlurb}>{v.blurb}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-24 md:mt-32">
        <CenterTextSection {...bottomSection} />
      </div>
      <div className="mt-24 md:mt-32">
        <Sponsors sponsors={clients} className={styles.clients} />
      </div>
      <VenueHireRequest
        open={location && location.hash === '#request'}
        onClose={() => (window.location = '#')}
      />
    </div>
    </>
  )
}

const VenueHirePage = ({ data, location }) => {
  return <VenueHireTemplate location={location} {...data} />
}

VenueHirePage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
    venueNodes: PropTypes.object,
  }),
}

export default VenueHirePage

export const pageQuery = graphql`
  query VenueHirePage {
    page: markdownRemark(fields: { slug: { eq: "/venue-hire/" } }) {
      frontmatter {
        header {
          title
          body
          bodyHtml
          image {
            childImageSharp {
              fluid(maxWidth: 1712, maxHeight: 1216, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          callToAction {
            label
            linkURL
          }
        }
        bottomSection {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1280, maxHeight: 720, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          body
          callToAction {
            label
            linkURL
          }
        }
        clients {
          label
          sponsorType
          group {
            linkURL
            logo {
              childImageSharp {
                fluid(maxWidth: 150, maxHeight: 80, quality: 90, fit: CONTAIN) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
          }
        }
        brochureForm {
          publicURL
        }
        bodyHtml
        body
      }
    }
    venueNodes: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "venues" } }
        frontmatter: { availableForHire: { eq: true } }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          blurb
          descriptors
          coverImage {
            childImageSharp {
              fluid(maxWidth: 375, maxHeight: 274, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
  }
`
